import * as React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import AboutSpanish from "../components/about_es"

import "../styles/home.css"

const BlogIndex = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata?.title || `Title`

  return (
    <Layout location={location} title={siteTitle}>
      <div>
        <header>
          <div className="text-xl md:text-2xl font-semibold drop-shadow">
            <p className="mb-1">
              <span className="text-2xl md:text-4xl mb-1 inline-block">
                👋 Hola, <strong className="name">soy Demóstenes</strong>
              </span>
              <br />
              Un Ingeniero, desarrollador web y móvil basado en Ciudad de Panamá.
            </p>
          </div>
        </header>

        <AboutSpanish />
      </div>
    </Layout>
  )
}

export default BlogIndex

/**
 * Head export to define metadata for the page
 *
 * See: https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-head/
 */
export const Head = () => (
  <Seo
    title="Ingeniero de Software, Desarrollador Web y Móvil de Panamá"
    description="Demóstenes García es un Ingeniero en Electrónica y Comunicaciones, desarrollador de software para la web y dispositivos móviles que está basado en Ciudad de Panamá, Panamá."
  />
)


export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
