import React, { Component } from 'react';
import { CSSTransition } from 'react-transition-group';
import { faCaretDown, faLanguage } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'gatsby';

export default class AboutEs extends Component {
  constructor() {
    super();
    this.state = { checked: false, ageMS: 0 };
    this.enableFullProfile = this.enableFullProfile.bind(this);
    this.renderFull = this.renderFull.bind(this);
  }

  componentWillUnmount() {
    if (this.timer) {
      clearInterval(this.timer);
    }
  }

  enableFullProfile(event) {
    event.preventDefault();
    this.setState({ checked: !this.state.checked });
  }

  renderButton() {
    if (this.state.checked) {
      return null;
    }

    return (
      <button className="btn-read-more text-slate-800" onClick={this.enableFullProfile}>
        Leer más <FontAwesomeIcon className="ml-1 text-slate-400" icon={faCaretDown} />
      </button>
    );
  }

  renderFull() {
    return (
      <CSSTransition in={this.state.checked} timeout={200} classNames="my-node" unmountOnExit>
        <>
          <ul className="list-disc ml-5">
            <li>Ing. en Electrónica y Comunicaciones</li>
            <li>Maestría en Gestión de Proyectos</li>
            <li>Maestría en Gestión y Tecnologías del Conocimiento (<a href="https://www.uc3m.es/" title="Universidad Carlos III de Madrid">UC3M</a>)</li>
            <li>Maestría en Desarrollo de Aplicaciones y Servicios para la Web (<a href="https://www.universidadviu.com/es/" title="Universidad Internacional de Valencia">VIU</a>)</li>
          </ul>
        </>
      </CSSTransition>
    );
  }

  render() {
    return (
      <div className="text-slate-300">
        <p className="text-lg">Más de 15 años de experiencia diseñando, arquitectando y desarrollando aplicaciones web y móviles.</p>

        <h2 className={`text-lg font-semibold mb-3 ${this.state.checked ? "" : "gradient-text-read-more"}`}>Educación</h2>

        {this.renderFull()}

        <div className="switcher-container mb-5">
          {this.renderButton()}
        </div>

        <Link to="/" className="btn-read-more btn-language text-slate-800">
          <FontAwesomeIcon icon={faLanguage} /> Read in English
        </Link>
      </div>
    );
  }
}
